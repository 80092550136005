import React from 'react'

function notfounh() {
  return (
    <div className="container">
      <div className="row pt-5">
        <div className="col text-center">
          <h1>Pagina no ENCONTRADA.</h1>
        </div>
      </div>
    </div>
  )
}

export default notfounh
