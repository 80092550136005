import React from 'react'
import './Footer.css'
export default function Footer() {
  return (
    <div className="externo">
      <div className="interno">
        <a href="mailto:murrutiam@ariztia.com"><i className="fas fa-terminal"></i>@marcuXo</a>
      </div>
    </div>
  )
}

